import React, { useEffect } from "react";
import { motion } from "framer-motion";

const Success = (props) => {
  useEffect(() => {
    // Auto close the alert after 2 seconds
    const timer = setTimeout(() => {
      props.onClose(); // Call the onClose function passed from the parent component
    }, 2500);

    // Clear the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [props]);

  return (
    <motion.div
      className="fixed bottom-0 inset-x-0 flex justify-center items-end py-4 px-6 pointer-events-none"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="bg-black  rounded-lg shadow-lg px-4 py-2 pointer-events-auto"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
      >
        <p className="p-2 text-white font-semibold text-xl">{props.text}!</p>
      </motion.div>
    </motion.div>
  );
};

export default Success;

