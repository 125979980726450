import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Cart from "../Cart";
import Navbar from "../Navbar";
import Navigator from "../Navigator";
import { LockClosedIcon, XIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";

const Profile = (props) => {
  const [user, setUser] = useState("");
  const [order, setOrder] = useState([]);
  const [orderID, setOrderId] = useState("");

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log(loggedInUser);
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  useEffect(() => {
    if (user !== "") {
      getCartData();
      getOrder();
    }
  }, [user]);

  const [cartlength, setCartLength] = useState(0);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCart(data["cart"]);
        setCartLength(data["cart"].length);
      });
  };

  const getOrder = () => {
    fetch(`https://albazaarkorea.com/api/getOrder/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setOrder(data);
      });
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeSuccuess = () => {
    setSuccess(false);
    console.log(success);
  };

  const successMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      closeSuccuess();
    }, 2000);
  };
  const [openOrderCart, setOpenOrderCart] = useState(false);
  const [selectedOrderCart, setSelectedOrderCart] = useState([]);

  const handleOrderCart = (cart, id) => {
    setOrderId(id);
    setSelectedOrderCart(cart);
    setOpenOrderCart(!openOrderCart);
  };

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  const [openNav, setOpenNav] = useState(false);
  const handleNavigator = () => {
    setOpenNav(!openNav);
  };

  const addtocart = (user, cart) => {
    axios
      .post("https://albazaarkorea.com/api/addCart", {
        phone: user,
        cart: cart,
      })
      .then(function (response) {
        getCartData();
        successMessage();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <Navbar
        homepage={false}
        user={user}
        handleSignout={handleSignout}
        handleCart={handleCart}
        cart={cart}
        cartlength={cartlength}
        handleNavigator={handleNavigator}
      />
      {order && (
        <div className="w-full flex justify-center fixed h-full mt-[100px] bg-slate-50">
          <div class="flex w-[80%] mt-12 flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden">
                  <table class="min-w-full text-center">
                    <thead class="border-b bg-black">
                      <tr>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          OrderID
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          class="text-sm font-medium text-white px-6 py-4"
                        >
                          Open
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.map((item, i) => {
                        return (
                          <tr class="bg-white border-b" key={i}>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.order_id}
                            </td>
                            <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              {item.date}
                            </td>
                            <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              {item.address_data.address}
                            </td>
                            <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              {item.receiver_name}
                            </td>
                            <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              {item.receiver_phone}
                            </td>
                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.status === "On Hold" && (
                                <div className="bg-gray-800 px-0 py-2 flex items-center justify-center rounded-md text-white">
                                  <div className="w-3 h-3 mr-2 bg-white rounded-full"></div>
                                  {item.status}
                                </div>
                              )}
                              {item.status === "Confirmed" && (
                                <div className="bg-blue-500 px-0 py-2 flex items-center justify-center rounded-md text-white">
                                  <div className="w-3 h-3 mr-2 bg-white rounded-full"></div>
                                  {item.status}
                                </div>
                              )}
                              {item.status === "Processing" && (
                                <div className="bg-orange-500 px-0 py-2 flex items-center justify-center rounded-md text-white">
                                  <div className="w-3 h-3 mr-2 bg-white rounded-full"></div>
                                  {item.status}
                                </div>
                              )}
                              {item.status === "Delivered" && (
                                <div className="bg-green-500 px-0 py-2 flex items-center justify-center rounded-md text-white">
                                  <div className="w-3 h-3 mr-2 bg-white rounded-full"></div>
                                  {item.status}
                                </div>
                              )}
                              {item.status === "Canceled" && (
                                <div className="bg-red-500 px-0 py-2 flex items-center justify-center rounded-md text-white">
                                  <div className="w-3 h-3 mr-2 bg-white rounded-full"></div>
                                  {item.status}
                                </div>
                              )}
                            </td>
                            <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              {Number(item.total).toLocaleString("ko-KR")}
                              <span className="text-[10px]">₩</span>
                            </td>
                            <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                              <button
                                className="bg-green-400 p-2 rounded-md text-black"
                                onClick={() =>
                                  handleOrderCart(item.cart, item.order_id)
                                }
                              >
                                See Cart
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openOrderCart && (
        <motion.div
          className="w-full h-screen  fixed top-0 left-0 flex justify-end"
          initial={{ y: "-100%" }}
          animate={{ y: 0 }}
          exit={{ y: "-100%" }}
          transition={{ duration: 0.3 }}
        >
          <div className="w-full h-screen  fixed top-0 left-0 flex justify-end  ">
            <div
              className="bg-[#00000050]  2xl:w-full xl:w-full lg-w-full md:w-full w-0"
              onClick={() => handleOrderCart()}
            ></div>
            <div className="mt-[80px] relative bg-slate-50  2xl:w-[30%] lg:w-[40%] md:w-[70%] w-full h-full overflow-y-auto shadow-sm">
              <div className="w-full h-16 mt-0 shadow-md flex px-4 justify-between rounded-sm bg-white">
                <button>
                  <p className="text-2xl font-bold ">Order ID - {orderID} </p>
                </button>
                <button
                  className="text-zinc-800 hover:text-red-600"
                  onClick={() => handleOrderCart()}
                >
                  <XIcon className="stroke-1 w-9 h-9" />
                </button>
              </div>
              <div className="px-3">
                <ul className="mt-[10px] space-y-3">
                  {selectedOrderCart &&
                    selectedOrderCart.map((item, i) => (
                      <li key={i}>
                        <div className="px-4 h-32 py-2 rounded-lg border bg-white  shadow-lg justify-center ">
                          <div className="flex pt-2 h-24  justify-between">
                            <img
                              src={item.images[0]}
                              width="85px"
                              height="85px"
                              className="rounded-lg object-cover"
                              alt=""
                            />
                            <div className="w-full pb-2 text-start grid grid-cols-1">
                              <p className="ml-4 pt-1 font-semibold">
                                {item.product_name}
                              </p>
                              <p className="ml-4 pt-1 font-semibold">
                                Quantity: {item.quantity}
                              </p>
                              <div className="w-full flex justify-between">
                                <button>
                                  <p className="ml-4 w-full font-semibold mt-1">
                                    Price:{" "}
                                    {Number(item.total).toLocaleString("ko-KR")}
                                    <span className="text-[14px]">₩</span>
                                  </p>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {openCart && (
        <Cart
          handleCart={handleCart}
          cart={cart}
          user={user}
          getCartData={getCartData}
        />
      )}
      {openNav && (
        <Navigator
          handleNavigator={handleNavigator}
          handleSignout={handleSignout}
        />
      )}
    </>
  );
};

export default Profile;
