import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cart from "../Cart";
import Navbar from "../Navbar";
import Navigator from "../Navigator";
import Success from "../Success";
import { AnimatePresence, motion } from "framer-motion"; // <-- Import motion components

const Product = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState("");
  const [product, setProduct] = useState({});
  const [image, setImage] = useState(0);
  const [option, setOption] = useState("");
  const [related, setRelated] = useState([]);

  const pickImage = (id) => {
    setImage(id);
  };

  useEffect(() => {
    fetch(`https://albazaarkorea.com/api/product/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setProduct(data);
      });
  }, [id]);

  useEffect(() => {
    fetch(`https://albazaarkorea.com/api/related/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRelated(data);
        console.log(data);
      });
  }, [id]);

  useEffect(() => {
    fetch(`https://albazaarkorea.com/api/option`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOption(data.shipping_policy_en);
      });
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log(loggedInUser);
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  useEffect(() => {
    if (user !== "") {
      getCartData();
    }
  }, [user]);

  const [cartlength, setCartLength] = useState(0);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCart(data["cart"]);
        setCartLength(data["cart"].length);
      });
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeSuccuess = () => {
    setSuccess(false);
    console.log(success);
  };

  const successMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      closeSuccuess();
    }, 2000);
  };

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  const addtocart = (user, cart) => {
    axios
      .post("https://albazaarkorea.com/api/addCart", {
        phone: user,
        cart: cart,
      })
      .then(function (response) {
        getCartData();
        successMessage();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [openNav, setOpenNav] = useState(false);
  const handleNavigator = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      <Navbar
        homepage={false}
        user={user}
        handleSignout={handleSignout}
        handleCart={handleCart}
        cart={cart}
        cartlength={cartlength}
        handleNavigator={handleNavigator}
      />
      {product.images && (
        <div className="w-full h-full 4xl:px-[600px] xxl:px-[400px] 2xl:px-[300px] 3xl:px-[100px] xl:px-[200px]  absolute ">
          <div className="px-8 sm:ml-32  bg-white mt-20 ">
            <div className="mt-[10px] w-full sm:flex justify-center">
              <div className="px-10 mt-10 lg:w-[700px] h-full">
                <div className="h-[520px] w-full">
                  {product && (
                    <img
                      src={product.images[image]}
                      className="w-full shadow-md rounded-md border-[1px] border-green-300 h-full object-fill object-center"
                      alt=""
                    />
                  )}
                </div>
                <div className="flex mt-[20px] gap-2">
                  {product.images &&
                    product.images.map((item, i) => {
                      return (
                        <img
                          src={item}
                          className="sm:w-[220px] sm:h-[220px] md:w-[130px] md:h-[150px] lg:w-[150px] lg:h-[150px] xl:w-[120px] xl:h-[120px] shadow-md rounded-md h-[100px] w-[100px]  object-cover cursor-pointer border-[1px] border-green-300"
                          onMouseOver={() => pickImage(i)}
                          alt=""
                        />
                      );
                    })}
                </div>
              </div>
              <div className="sm:w-[50%] lg:mt-[20px] p-4 mt-[10px]">
                <div className="mt-10">
                  <p className="text-2xl font-bold">{product.title_en}</p>
                  <div className="flex gap-4 pt-2">
                    <p className="text-xl">Category:</p>
                    <p className="text-xl font-medium">{product.category_en}</p>
                  </div>
                  <div className="flex gap-4 pt-2">
                    <p className="text-xl">Origin:</p>
                    <p className="text-xl font-medium">{product.origin}</p>
                  </div>
                  <div className="flex gap-4 pt-2">
                    <p className="text-xl">Brand / State:</p>
                    <p className="text-xl font-medium">{product.brand}</p>
                  </div>
                  <div className="flex gap-4 pt-2">
                    <p className="text-xl">Weight:</p>
                    <p className="text-xl font-medium">{product.weight}</p>
                  </div>
                  <p class="space-x-2 pt-2 mt-2">
                    <span class="text-2xl font-bold">
                      {Number(product.fixed_price).toLocaleString("ko-KR")}
                      <span className="text-sm">₩</span>
                    </span>
                    {product.sale !== 0 ? (
                      <span class="text-md line-through text-gray-500">
                        {product.price}
                        <span className="text-[12px]">₩</span>
                      </span>
                    ) : (
                      ""
                    )}
                    {product.sale !== 0 ? (
                      <span class="text-md text-red-700">{product.sale}%</span>
                    ) : (
                      ""
                    )}
                  </p>

                  <div className="bg-slate-50 px-4 py-4 rounded-md mt-8">
                    <p>
                      <b> Welcome to Al Bazaar! </b>
                    </p>
                    <p>
                      {" "}
                      We're thrilled to offer you speedy delivery for your
                      grocery needs. Here's a quick rundown of our shipping
                      process:
                    </p>
                    <br />
                    <p>
                      {" "}
                      <b>Delivery Time:</b> Orders within Korea are typically
                      delivered within 1 business day, as long as they are
                      placed by 3pm. Please note that delivery to Jeju might
                      take 2 days due to geographical considerations.{" "}
                    </p>
                    <br />
                    <p>
                      {" "}
                      <b> Cut-off Time: </b> For same-day shipping, make sure to
                      place your order by 3pm. Orders received after this time
                      will be processed the next business day.{" "}
                    </p>
                    <p>
                      {" "}
                      Jeju Orders on Friday: If you're placing an order on
                      Friday for delivery to Jeju, please be aware that due to
                      the extended delivery timeframe, your order will be
                      shipped the following week to ensure freshness.
                    </p>
                    <br />
                    <p>
                      Thank you for choosing Al Bazaar! <br /> If you have any
                      questions, our support team is ready to assist you. Happy
                      shopping!
                    </p>
                  </div>

                  {product.avail !== 0 ? (
                    <div
                      className="w-full h-14 mt-8 bg-zinc-300 hover:bg-green-500 text-center text-sm text-black rounded duration-300 hover:text-white flex cursor-pointer justify-center"
                      onClick={() => {
                        addtocart(user, {
                          images: product.images,
                          product_id: product._id,
                          product_name: product.title_en,
                          product_price: product.fixed_price,
                          quantity: 1,
                          total: 1 * product.fixed_price,
                        });
                      }}
                    >
                      <button className="text-xl font-bold">Add to Cart</button>
                    </div>
                  ) : (
                    <div className="w-full h-14 mt-8 bg-zinc-300 hover:bg-gray-900 text-center text-sm text-black rounded duration-300 hover:text-white flex cursor-pointer justify-center">
                      <button className="text-xl font-bold">
                        Out of Stock
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-28  h-[600px] w-full px-4">
              <h1 className=" flex justify-center items-center font-bold text-xl mt-2 mb-8 bg-green-300 text-black p-3 rounded-lg">
                Related Products
              </h1>
              <div className="flex p-8 overflow-x-auto space-x-8">
                {related.map((product) => (
                  <motion.div
                    key={product._id}
                    className="relative bg-white border-[2px] border-gray-100 rounded-md hover:border-[1px] hover:border-green-400 hover:shadow-lg"
                    whileHover={{ scale: 1.05 }}
                  >
                    <div className="relative w-[200px] 2xl:h-[300px]">
                      <img
                        className="w-full h-32  object-center object-cover lg:w-full rounded-tl-lg rounded-tr-lg lg:h-52 lg:aspect-none 2xl:h-[240px] "
                        src={product.images[0]}
                        alt={product.title_en}
                      />

                      <div className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300 hover:bg-black hover:bg-opacity-40 hover:rounded-md">
                        <div className="w-32 h-8 bg-black shadow-lg rounded-lg flex items-center justify-center hover:bg-green-500">
                          {props.user !== "" ? (
                            <button
                              className="flex justify-center items-center"
                              onClick={() => {
                                addtocart(user, {
                                  images: product.images,
                                  product_id: product._id,
                                  product_name: product.title_en,
                                  product_price: product.fixed_price,
                                  weight: product.weight,
                                  quantity: 1,
                                  total: 1 * product.fixed_price,
                                });
                              }}
                            >
                              <p className="text-white font-semibold text-sm">
                                Add to Cart
                              </p>
                            </button>
                          ) : (
                            <Link to="/login">
                              <button>
                                <p className="text-white font-semibold text-sm">
                                  Add to Cart
                                </p>
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                      <img
                        className="absolute top-1 right-1 w-10 h-10"
                        src="/halal.png"
                        alt="Placeholder"
                      />
                      {product.sale !== 0 && (
                        <div className="absolute top-1 right-0 left-1 flex justify-center items-center w-10 h-6 ml-1 mt-2 bg-red-500 rounded-md text-center">
                          <p className="text-white text-[10px]">SALE</p>
                        </div>
                      )}
                    </div>

                    <div className="px-3 pt-2 space-y-1 mt-2 mb-2">
                      <div className="h-20">
                        <Link
                          to={`/product/${product._id}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <p className="space-x-2">
                            <span className="text-xl font-semibold">
                              {Number(product.fixed_price).toLocaleString(
                                "ko-KR"
                              )}
                              <span className="text-sm">₩</span>
                            </span>
                            {product.sale !== 0 && (
                              <span className="text-sm line-through text-gray-500">
                                {Number(product.price).toLocaleString("ko-KR")}
                                <span className="text-[10px]">₩</span>
                              </span>
                            )}
                            {product.sale !== 0 && (
                              <span className="text-sm text-red-700">
                                {product.sale}%
                              </span>
                            )}
                          </p>
                          <p className="text-md">
                            {product.title_en.length > 30 ? (
                              <span title={product.title_en}>
                                {product.title_en.slice(0, 20)}...
                              </span>
                            ) : (
                              product.title_en
                            )}{" "}
                            <span className="text-[12px]">
                              ({product.weight})
                            </span>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            <div className="h-[150px] w-full"></div>
          </div>
          {openCart && (
            <Cart
              handleCart={handleCart}
              cart={cart}
              user={user}
              getCartData={getCartData}
            />
          )}
          {success && <Success text="Product Added Successfully" />}
          {openNav && (
            <Navigator
              handleNavigator={handleNavigator}
              handleSignout={handleSignout}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Product;
