import { LockClosedIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Hero from "./Hero";
import Popup from "./Popup";
import Products from "./Product";

const Main = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const [detproduct, setDetProduct] = useState({});

  const sedDetail = (data) => {
    setIsOpen(!isOpen);
    setDetProduct(data);
  };

  useEffect(() => {
    // Add the following code to disable horizontal scroll on mount
    document.body.style.overflowX = "hidden";

    // Clean up the code to re-enable horizontal scroll on unmount
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  return (
    <>
      {props.products && (
        <div className="bg-slate-50">
          <div className="ml-80 sm:ml-80 md:ml-80 lg:ml-80 md:pt-[100px] pt-10 px-8 h-full  ">
            <Hero handleSearch={props.handleSearch} searchItem={props.searchItem} />
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { duration: 0.5 } },
              }}
            >
              <Products
                products={props.isSearching === true ? props.searchproduct : props.products.slice(0, props.showItem)}
                user={props.user}
                addtocart={props.addtocart}
              />
            </motion.div>
            {props.products && props.products.length !== 0 ? (
              <div className="h-32 text-center items-center w-full">
                <button className="mt-12 rounded-md bg-zinc-400 px-8 py-3 text-white hover:bg-green-500" onClick={props.handleShowMore}>
                  Load More
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="md:ml-80 mt-24 flex items-center px-4  h-[500px] bg-white">
            <img src="app.png" className="w-[40%] py-8 h-full object-contain" alt="" />
            <div className="w-[50%]">
              <h1 className="font-bold text-2xl">Get things done with Albazaar App</h1>
              <p class="text-gray-700 text-md font-light">Easy to get started!</p>
              <a href="https://play.google.com/store/apps/details?id=com.albazaar.albazaar_app" target="_blank">
                <button className="bg-black mt-2 mb-2 px-2 py-4 sm:w-[200px] rounded-xl mr-4 text-white hover:bg-green-500">Download Android</button>
              </a>
              <a href="https://apps.apple.com/kr/app/albazaar-korea/id1613283664?l=en" target="_blank">
                <button className="bg-black px-2 py-4 sm:w-[200px] rounded-xl mr-8 text-white hover:bg-green-500">Download IOS</button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
