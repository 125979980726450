import { ShoppingBagIcon } from "@heroicons/react/outline";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const halal = '../../public/halal.png';
const Products = (props) => {
  return (
    <div>
      {props.products && props.products.length !== 0 ? (
        <div className="mt-20 grid grid-cols-1 gap-y-6 gap-x-3 sm:grid-cols-2 lg:grid-cols-5 3xl:grid-cols-5 md:grid-cols-4 2xl:gap-x-2 2xl:grid-cols-5 ">
          {props.products.map((product) => (
            <motion.div
              key={product._id}
              className="relative bg-white border-[2px] border-gray-100 rounded-md hover:border-[1px] hover:border-green-400 hover:shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <div className="relative">
                <img
                  className="w-full h-32  object-center object-cover lg:w-full rounded-tl-lg rounded-tr-lg lg:h-52 lg:aspect-none 2xl:h-52"
                  src={product.images[0]}
                  alt={product.title_en}
                />

                <div className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300 hover:bg-black hover:bg-opacity-40 hover:rounded-md">
                  <div className="w-32 h-8 bg-black shadow-lg rounded-lg flex items-center justify-center hover:bg-green-500">
                    {props.user !== "" ? (
                      <button
                        className="flex justify-center items-center"
                        onClick={() => {
                          props.addtocart(props.user, {
                            images: product.images,
                            product_id: product._id,
                            product_name: product.title_en,
                            product_price: product.fixed_price,
                            weight: product.weight,
                            quantity: 1,
                            total: 1 * product.fixed_price,
                          });
                        }}
                      >
                        <p className="text-white font-semibold text-sm">
                          Add to Cart
                        </p>
                      </button>
                    ) : (
                      <Link to="/login">
                        <button>
                          <p className="text-white font-semibold text-sm">
                            Add to Cart
                          </p>
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <img
                  className="absolute top-1 right-1 w-10 h-10"
                  src='/halal.png'
                  alt="Placeholder"
                />
                {product.sale !== 0 && (
                  <div className="absolute top-1 right-0 left-1 flex justify-center items-center w-10 h-6 ml-1 mt-2 bg-red-500 rounded-md text-center">
                    <p className="text-white text-[10px]">SALE</p>
                  </div>
                )}
              </div>

              <div className="px-3 pt-2 space-y-1 mt-0 mb-2">
                <div className="h-20">
                  <Link to={`/product/${product._id}`}>
                    <p className="space-x-2">
                      <span className="text-xl font-semibold">
                        {Number(product.fixed_price).toLocaleString('ko-KR')}
                        <span className="text-sm">₩</span>
                      </span>
                      {product.sale !== 0 && (
                        <span className="text-sm line-through text-gray-500">
                          {Number(product.price).toLocaleString('ko-KR')}
                          <span className="text-[10px]">₩</span>
                        </span>
                      )}
                      {product.sale !== 0 && (
                        <span className="text-sm text-red-700">
                          {product.sale}%
                        </span>
                      )}
                    </p>
                    <p className="text-md">
                      {product.title_en.length > 25 ? (
                        <span title={product.title_en}>
                          {product.title_en.slice(0, 20)}...
                        </span>
                      ) : (
                        product.title_en
                      )}{" "}
                      <span className="text-[12px]">({product.weight})</span>
                    </p>
                  </Link>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <div className="w-full flex justify-center text-center h-full mt-20">
          <div className="w-[320px] grid c-1 h-32 place-content-center  rounded-xl shadow-sm text-center bg-zinc-200">
            <ShoppingBagIcon className="w-12 h-12 ml-[80px]  text-black" />
            <p className="text-2xl text-center font-semibold text-black">
              Product not found!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;

