import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Sidebar = (props) => {
  const categories = props.category;
  const [selectedCategory, setSelectedCategory] = useState(null);

  const sidebarVariants = {
    hidden: { x: -100 },
    visible: { x: 0, transition: { duration: 0.5 } },
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  return (
    <motion.aside
      className="fixed md:w-80 md:fixed md:mt-[80px] md:overflow-scroll md:shadow-md lg:w-80 lg:fixed  lg:overflow-scroll lg:shadow-md"
      aria-label="Sidebar"
      variants={sidebarVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="w-full px-3 h-screen overflow-y-auto bg-white">
        <ul className="md:mt-[20px] space-y-3 mt-[110px]">
          {categories &&
            categories.map((category) => (
              <li key={category._id}>
                <motion.div
                  className="p-2 bg-slate-100 rounded-lg justify-center hover:bg-green-500 hover:text-white hover:font-bold"
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleCategoryClick(category)}
                >
                  <div className="flex items-center">
                    <img
                      src={category.image}
                      width="30px"
                      height="30px"
                      alt=""
                      className="ml-2"
                    />
                    <p className="ml-4 text-center font-semibold text-sm">
                      {category.name_en}
                    </p>
                  </div>
                </motion.div>
                {selectedCategory === category && (
                  <ul className="ml-4 mt-2 space-y-2">
                    {category.categories.map((subcategory) => (
                      <li key={subcategory._id}>
                        <Link
                          to={`/category/${subcategory.name_en}`}
                          className="text-gray-600 hover:text-gray-900"
                        >
                           <motion.div
                  className="p-2 bg-slate-100 rounded-lg justify-center hover:bg-green-500 hover:text-white hover:font-bold"
                  whileHover={{ scale: 1.05 }}
                  onClick={() => handleCategoryClick(category)}
                >
                  <div className="flex items-center">
                    <img
                      src={subcategory.image}
                      width="30px"
                      height="30px"
                      alt=""
                      className="ml-2"
                    />
                    <p className="ml-4 text-center font-semibold text-sm">
                      {subcategory.name_en}
                    </p>
                  </div>
                </motion.div>
                          
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          <li>
            <div className="h-32"></div>
          </li>
        </ul>
      </div>
    </motion.aside>
  );
};

export default Sidebar;
