import React from "react";
import SearchProduct from "./Search";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const Hero = (props) => {
  const [banner, setBanner] = useState("");
  useEffect(() => {
    fetch("https://albazaarkorea.com/api/ads")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBanner(data[0]["image"]);
      });
  }, []);

  const textVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <div className="w-full h-[400px] rounded-2xl justify-center shadow-sm relative p-20">
      <div
        className=" absolute inset-0 flex items-center justify-center bg-cover bg-bottom rounded-2xl"
        style={{
          backgroundImage:
            "url(https://hips.hearstapps.com/hmg-prod/images/healthy-groceries-1525213305.jpg)",
        }}
      >
        <div className="flex justify-center items-center bg-black h-full w-full rounded-2xl bg-opacity-60">
          <div className="flex flex-col justify-center items-center">
            <motion.h1
              className="text-5xl font-bold text-white"
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              Welcome to Albazaar!
            </motion.h1>
            <motion.p
              className="text-lg  text-white mt-4"
              variants={textVariants}
              initial="hidden"
              animate="visible"
            >
              Your trusted source for halal meats and groceries in South Korea.
            </motion.p>
          </div>
        </div>
      </div>
      <form className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 w-full max-w-3xl px-3 py-6 overflow-hidden">
        <div className="h-1/2 overflow-auto">
          <SearchProduct
            handleSearch={props.handleSearch}
            searchItem={props.searchItem}
          />
        </div>
      </form>
    </div>
  );
};

export default Hero;
