import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/About";
import Category from "./components/Category/Category";
import Checkout from "./components/Checkout/Checkout";
import Home from "./components/Home";
import Product from "./components/Product/Product";
import Profile from "./components/Profile/Profile";
import SignIn from "./components/SingIn/Singin";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
       <Route exact path="/" element={<Home/>}></Route> 
       <Route  path="/login" element={<SignIn />}></Route> 
       <Route  path='/category/:id' element={<Category />}></Route>
       <Route  path='/product/:id' element={<Product/>}></Route>
       <Route  path='/checkout' element={<Checkout/>}></Route>
       <Route  path='/profile' element={<Profile/>}></Route>
       <Route  path='/about' element={<About/>}></Route>    
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
