import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { HeartIcon, MenuAlt1Icon, PhoneIcon, ShoppingBagIcon, ShoppingCartIcon, UserCircleIcon } from "@heroicons/react/outline";
import SearchProduct from "./Search";
import Cart from "./Cart";
import { SocialIcon } from "react-social-icons";

const Navbar = (props) => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const [scrollY, setScrollY] = useState(0);
  const [prevCartLength, setPrevCartLength] = useState(0);


  useEffect(() => {
    setPrevCartLength(props.cartlength);
  }, [props.cartlength]);
  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);

  const navbarVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="w-[100%] h-[80px]  z-10 bg-white fixed drop-shadow-lg"
      variants={navbarVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex ml-4 items-center">
          <button onClick={() => props.handleNavigator()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <Link to="/">
            <h1 className="text-[50px] text-green-500 font-semibold font-bebas mr-4 ml-4 drop-shadow-sm">Albazaar</h1>
          </Link>
        </div>
        {scrollY > 450 && props.homepage ? (
          <motion.div className="hidden md:block w-[700px]" whileHover={{ scale: 1.05 }}>
            <SearchProduct handleSearch={props.handleSearch} searchItem={props.searchItem} />
          </motion.div>
        ) : (
          ""
        )}
        <div className="flex px-2 justify-center items-center">
          <div className="px-1"></div>
          <div className="pr-2">
            <li class="font-sans block lg:inline-block lg:mt-0  align-middle text-black hover:text-gray-700">
              <button class="relative flex mt-1 px-3 py-2 bg-green-500 font-semibold rounded-lg shadow-sm text-white items-center">
                <p>010-8779-0672</p>
              </button>
            </li>
          </div>
          <div className="pr-2">
            <li class="font-sans block lg:inline-block lg:mt-0  align-middle text-black hover:text-gray-700">
              <button class="relative flex mt-1">
                <SocialIcon network="instagram" url="https://www.instagram.com/albazaarkorea/?hl=ko" target="_blank" style={{ height: 30, width: 30 }} />
              </button>
            </li>
          </div>
          <div className="pr-2">
            <li class="font-sans block lg:inline-block lg:mt-0  align-middle text-black hover:text-gray-700">
              <button class="relative flex mt-1">
                <SocialIcon network="facebook" url="https://www.facebook.com/albazaarkorea/" target="_blank" style={{ height: 30, width: 30 }} />
              </button>
            </li>
          </div>
          <div className="pr-2">
  <li class="font-sans block lg:inline-block lg:mt-0  align-middle text-black hover:text-gray-700">
    <button class="relative flex" onClick={() => props.handleCart()}>
      <ShoppingBagIcon className="w-9 h-9 stroke-1 hover:w-10 hover:h-10" />
      <motion.span
        className="absolute right-0 top-0 rounded-full bg-red-600 w-5 h-5 top right p-0 m-0 text-white font-mono text-[12px] flex justify-center items-center  leading-tight text-center"
        initial={{ scale: 1 }}
        animate={{ scale: [1, 1.2, 1], transition: { duration: 0.2 } }}
        key={props.cartlength} // Add a unique key for the motion component to detect changes
      >
        {props.cartlength}
      </motion.span>
    </button>
  </li>
</div>


          {props.user !== "" ? (
            ""
          ) : (
            <motion.div
              className="w-20 h-10 mr-2 flex items-center justify-center bg-zinc-200 rounded-md shadow-sm  text-center hover:bg-green-500 duration-300 hover:text-white"
              whileHover={{ scale: 1.05 }}
            >
              <Link to="/login">
                <p className="text-center text-md">Sign In</p>
              </Link>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Navbar;
