import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Main from "./Main";
import Sidebar from "./Sidebar";
import { useLayoutEffect } from "react";
import Cart from "./Cart";
import axios from "axios";
import Success from "./Success";
import Navigator from "./Navigator";
import { AnimatePresence, motion } from "framer-motion";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    fetch("https://albazaarkorea.com/api/subcategory")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategory(data);
      });
  }, []);

  const [user, setUser] = useState("");

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    const isDesktop = window.innerWidth > 1920;
    console.log(window.innerWidth);
    setIsMobile(isMobile);
    setIsDesktop(isDesktop);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  const [products, setProducts] = useState();
  const [showItem, setShowItem] = useState(18);
  const handleShowMore = () => setShowItem(showItem + 18);
  const [isSearching, setSearch] = useState(false);
  const [searchproduct, setSearchProduct] = useState([]);
  const [searchItem, setSearchItem] = useState("");

  const handleSearch = (item) => {
    if (item !== "") {
      setSearch(true);
      setSearchItem(item);
      const newprods = products.filter((prod) =>
        prod.title_en.toLowerCase().includes(item.toLowerCase())
      );
      setSearchProduct(newprods);
    } else {
      setSearch(false);
      setSearchItem("");
    }
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [cartlength, setCartLength] = useState(0);
  const [success, setSuccess] = useState(false);

  const closeSuccuess = () => {
    setSuccess(false);
    console.log(success);
  };

  const successMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      closeSuccuess();
    }, 1000);
  };

  useEffect(() => {
    if (user !== "") {
      getCartData();
    }
  }, [user]);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCart(data["cart"]);
        setCartLength(data["cart"].length);
      });
  };

  const addtocart = (user, cart) => {
    axios
      .post("https://albazaarkorea.com/api/addCart", {
        phone: user,
        cart: cart,
      })
      .then(function (response) {
        getCartData();
        successMessage();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  const [openNav, setOpenNav] = useState(false);
  const handleNavigator = () => {
    setOpenNav(!openNav);
  };

  useEffect(() => {
    fetch("https://albazaarkorea.com/api/product")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let d = data.filter((item) => item.avail !== 0);
        setProducts(d.reverse());
      });
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState("");

  useEffect(() => {
    const storedShowPopup = localStorage.getItem("showPopup");
    const lastClickedDate = localStorage.getItem("lastClickedDate");
    const todayDate = new Date().toISOString().slice(0, 10);

    if (storedShowPopup === "false" && lastClickedDate === todayDate) {
      setShowPopup(false);
    } else {
      fetch("https://albazaarkorea.com/api/popup")
        .then((response) => response.json())
        .then((data) => {
          setShowPopup(data.show);
          setPopupImageUrl(data.image);
        });
    }
  }, []);

  const handleClosePopup = () => {
    const todayDate = new Date().toISOString().slice(0, 10);
    setShowPopup(false);
    localStorage.setItem("lastClickedDate", todayDate);
  };

  return (
    <>
      {isMobile ? (
        <div className="flex h-full flex-col justify-center items-center  bg-white">
          <div className="flex items-center justify-center mt-2  shadow-lg w-full">
            <h1 className="text-[50px] text-green-500 font-semibold font-bebas mr-4 ml-4 drop-shadow-sm">
              Albazaar
            </h1>
          </div>
          <img
            src="app.png"
            className="w-[90%] max-h-[100vh] mt-8 object-contain mb-8"
            alt=""
          />
          <div className="text-center mt-10">
            <h1 className="font-bold text-xl">
              Get things done with Albazaar App
            </h1>
            <p className="text-gray-700 text-md font-light mt-2">
              Easy to get started!
            </p>
            <div className="flex items-center justify-center mt-8">
              <a
                href="https://play.google.com/store/apps/details?id=com.albazaar.albazaar_app"
                target="_blank"
              >
                <button className="bg-black text-white px-4 py-2 rounded-xl hover:bg-green-500">
                  Download Android
                </button>
              </a>
              <a
                href="https://apps.apple.com/kr/app/albazaar-korea/id1613283664?l=en"
                target="_blank"
              >
                <button className="bg-black text-white ml-4 px-4 py-2 rounded-xl hover:bg-green-500">
                  Download iOS
                </button>
              </a>
            </div>
            <div className="pt-20"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-screen bg-slate-100">
            <Navbar
              homepage={true}
              handleSearch={handleSearch}
              searchItem={searchItem}
              user={user}
              handleSignout={handleSignout}
              handleCart={handleCart}
              cartlength={cartlength}
              handleNavigator={handleNavigator}
            />
            <div className="px-0 4xl:px-[600px] xxl:px-[400px] 2xl:px-[300px] 3xl:px-[100px] xl:px-[200px]">
              <Sidebar category={category} />
              <Main
                handleSearch={handleSearch}
                handleShowMore={handleShowMore}
                products={products}
                isSearching={isSearching}
                searchproduct={searchproduct}
                showItem={showItem}
                searchItem={searchItem}
                user={user}
                addtocart={addtocart}
              />
              {success && <Success text="Product Added Successfully" />}
              {openCart && (
                <Cart
                  handleCart={handleCart}
                  cart={cart}
                  user={user}
                  getCartData={getCartData}
                />
              )}
              {openNav && (
                <Navigator
                  handleNavigator={handleNavigator}
                  handleSignout={handleSignout}
                />
              )}
            </div>
          </div>

          <AnimatePresence>
            {showPopup && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.2 }}
                className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-opacity-70 bg-black z-50"
              >
                <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-opacity-70 bg-black z-50  overscroll-y-auto">
                  <div className="p-8 bg-white rounded-lg shadow-lg w-[600px]">
                    {" "}
                    {/* Adjust the width here */}
                    <img
                      src={popupImageUrl}
                      alt="Popup"
                      className="w-full h-auto"
                    />
                    <div className="flex justify-center mt-4">
                      <button
                        onClick={handleClosePopup}
                        className="mr-4 bg-black text-white font-semibold px-4 py-2 rounded-md hover:bg-red-500"
                      >
                        Close
                      </button>
                      <button
                        onClick={() => {
                          handleClosePopup();
                          localStorage.setItem("showPopup", "false");
                        }}
                        className="bg-black text-white font-semibold px-4 py-2 rounded-md hover:bg-red-500"
                      >
                        Don't Show Today
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export default Home;
