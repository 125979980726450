import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Navbar from "../Navbar";
import { Disclosure } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/solid";
import Cart from "../Cart";
import Success from "../Success";
import { XIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import Navigator from "../Navigator";
import { useLayoutEffect } from "react";
import { motion } from "framer-motion";

const Checkout = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const getOption = () => {
    fetch(`https://albazaarkorea.com/api/option`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOption(data);
      });
  };

  const [user, setUser] = useState("");
  const [userData, setUserData] = useState(null);
  const [subtotal, setSubtotal] = useState("");

  const [shipping, setShipping] = useState("");
  const [total, setTotal] = useState("");
  const paymentMethods = [
    //{ id: 'credit-card', title: 'Credit card' },
    { id: "direct", title: "Direct Bank Transfer" },
  ];

  const [receiverName, setName] = useState("");
  const [saveInfo, setInfo] = useState(false);
  const [receiverPhone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [orderNote, setNote] = useState(" ");
  const [payment, setPayment] = useState("Credit card");
  const [cardNumber, setCard] = useState("");
  const [birthday, setBirthday] = useState("");
  const [dateExp, setDateExp] = useState("");
  const [pass, setPass] = useState("");
  const [option, setOption] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [userinfoMessage, setUserErrorMessage] = useState(false);

  const [pmethod, setPayMethod] = useState(0);

  const selectPayment = (id) => {
    setPayMethod(id);
    if (id === 0) {
      //setPayment("Credit card")
      //setPayMethod(0)
      setPayment("Bank Transfer");
      setPayMethod(0);
    } else {
      setPayment("Bank Transfer");
      setPayMethod(1);
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log(loggedInUser);
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  useEffect(() => {
    if (user !== "") {
      getOption();
      getCartData();
      fetch(`https://albazaarkorea.com/api/getUser/${user}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setUserData(data);
          setPhone(data?.address?.[0]?.phone);
          setName(data?.address?.[0]?.name);
          if (data?.address?.[0]?.type === "text") {
            setAddress(data?.address?.[0]?.address);
          } else {
            setAddress("");
          }
        });
    }
  }, [user]);

  const [cartlength, setCartLength] = useState(0);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCart(data["cart"]);
        setCartLength(data["cart"].length);
        let num = 0;
        for (let i = 0; i < data["cart"].length; i++) {
          console.log(data["cart"][i]);
          num += data["cart"][i]["total"];
        }
        setSubtotal(num);

        let t = num;
        setTotal(t);
      });
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const closeSuccuess = () => {
    setSuccess(false);
    console.log(success);
  };

  const successMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      closeSuccuess();
      navigate("/");
    }, 3000);
  };

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  const addtocart = (user, cart) => {
    axios
      .post("https://albazaarkorea.com/api/addCart", {
        phone: user,
        cart: cart,
      })
      .then(function (response) {
        getCartData();
        successMessage();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const UpdateUserInfo = () => {
    let userInfo = {
      user_id: user,

      name: receiverName,
      phone: receiverPhone,
      address: address,
      type: "text",
      address_id: "webid",
    };
    console.log(saveInfo);
    if (saveInfo) {
      axios
        .post("https://albazaarkorea.com/api/update/user", userInfo)
        .then((response) => {
          console.log(response.status);
        });
    }
  };

  const checkOut = () => {
    let d = new Date().toLocaleString() + "";
    if (pmethod === 0) {
      console.log("bank transfer");
      let order_body = {
        user_id: user,
        date: d,
        address_id: "Webid",
        receiver_name: receiverName,
        receiver_phone: receiverPhone,
        status: "On Hold",
        payment_type: "Direct Bank Transfer",
        shippingReq: orderNote,
        total: parseInt(total >= 100000 ? total : total + option["shipping"]),
        cart: cart,
        address_data: {
          name: receiverName,
          phone: user,
          address: address,
          type: "text",
          address_id: "webid",
        },
      };
      axios
        .post("https://albazaarkorea.com/api/makeOrder", order_body)
        .then((response) => {
          axios
            .post("https://albazaarkorea.com/api/sendMessage", {
              phone: user,
              total: parseInt(
                total >= 100000 ? total : total + option["shipping"]
              ),
            })
            .then((response) => {
              successMessage();
              UpdateUserInfo();
            });
        })
        .catch((error) => {
          console.log("error");
        });
      /*console.log("Credit Card")
        
        let card_info = {
          phone : user,
          name: receiverName,
          cardNumber: cardNumber,
          expiryDate: dateExp,
          birth: birthday,
          total: parseInt(total + option['shipping']),
          password: pass,
        }
        axios.post("https://albazaarkorea.com/api/pay", card_info).then((response)=>{
          console.log(response.status)
          let order_body = {
            user_id: user,
            date: d,
            address_id: "Webid",
            receiver_name: receiverName,
            receiver_phone: receiverPhone,
            status: "On Hold",
            payment_type: "Card Payment",
            shippingReq: orderNote,
            total: parseInt(total + option['shipping']),
            cart: cart,
            address_data: {
                name: receiverName,
                phone: user,
                address: address,
                type: 'text',
                address_id: "webid"
            }
          }
          axios.post('https://albazaarkorea.com/api/makeOrder', order_body).then((response)=>{
            successMessage();
            UpdateUserInfo();
          }).catch((error)=>{
            console.log("error")
          })
        }).catch((error)=>{
          console.log(error)
          setErrorMessage(true)
        })*/
    } else {
      console.log("Error");
    }
  };

  const [openNav, setOpenNav] = useState(false);
  const handleNavigator = () => {
    setOpenNav(!openNav);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
    >
      <Navbar
        homepage={false}
        user={user}
        handleSignout={handleSignout}
        handleCart={handleCart}
        cart={cart}
        cartlength={cartlength}
        handleNavigator={handleNavigator}
      />
      {option && cart && (
        <main className="lg:min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse md:pt-[80px]">
          <h1 className="sr-only">Checkout</h1>

          {/* Mobile order summary */}
          <section
            aria-labelledby="order-heading"
            className="pt-[100px] bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
          >
            <Disclosure as="div" className="max-w-lg mx-auto">
              {({ open }) => (
                <>
                  <div className="flex items-center justify-between">
                    <h2
                      id="order-heading"
                      className="text-lg font-medium text-gray-900"
                    >
                      Your Order
                    </h2>
                    <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                      {open ? (
                        <span>Hide full summary</span>
                      ) : (
                        <span>Show full summary</span>
                      )}
                    </Disclosure.Button>
                  </div>

                  <Disclosure.Panel>
                    <ul
                      role="list"
                      className="divide-y divide-gray-200 border-b border-gray-200"
                    >
                      {cart.map((product) => (
                        <li key={product.id} className="flex py-6 space-x-6">
                          <img
                            src={product.images[0]}
                            className="flex-none w-40 h-40 object-center object-cover bg-gray-200 rounded-md"
                          />
                          <div className="flex flex-col justify-between space-y-4">
                            <div className="text-sm font-medium space-y-1">
                              <h3 className="text-gray-900">
                                {product.product_name}
                              </h3>
                              <p className="text-gray-900">
                                {Number(product.product_price).toLocaleString(
                                  "ko-KR"
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
                      <div className="flex justify-between">
                        <dt>Subtotal</dt>
                        <dd className="text-gray-900">
                          {Number(subtotal).toLocaleString("ko-KR")}
                          <span className="text-sm">₩</span>
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Shipping</dt>
                        <dd className="text-gray-900">
                          {subtotal >= 100000 ? 0 : option["shipping"]}
                          <span className="text-sm">₩</span>{" "}
                        </dd>
                      </div>
                    </dl>
                  </Disclosure.Panel>

                  <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
                    <span className="text-base">Total</span>
                    <span className="text-base">
                      {subtotal >= 100000 ? total : total + option["shipping"]}
                      <span className="text-sm">₩</span>
                    </span>
                  </p>
                </>
              )}
            </Disclosure>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="hidden bg-gray-50 w-full max-w-md flex-col lg:flex"
          >
            <h2 id="summary-heading" className="sr-only">
              Order summary
            </h2>

            <div className="flex-none bg-gray-50 border-t border-gray-200 p-6">
              <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">
                    {Number(subtotal).toLocaleString("ko-KR")}
                    <span className="text-sm">₩</span>
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt>Shipping</dt>
                  <dd className="text-gray-900">
                    {subtotal >= 100000
                      ? 0
                      : Number(option["shipping"]).toLocaleString("ko-KR")}
                    <span className="text-sm">₩</span>
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">
                    {subtotal >= 100000
                      ? subtotal
                      : Number(subtotal + option["shipping"]).toLocaleString(
                          "ko-KR"
                        )}
                    <span className="text-sm">₩</span>
                  </dd>
                </div>
              </dl>
            </div>

            <ul
              role="list"
              className="flex-auto overflow-y-auto divide-y divide-gray-200 px-6"
            >
              {cart.map((product) => (
                <li key={product.id} className="flex py-6 space-x-6">
                  <img
                    src={product.images[0]}
                    alt=""
                    className="flex-none w-20 h-20 object-center object-cover bg-gray-200 rounded-md"
                  />
                  <div className="flex flex-col justify-between space-y-4">
                    <div className="text-sm font-medium space-y-1">
                      <h3 className="text-gray-900">{product.product_name}</h3>
                      <div className="flex">
                        <p className="text-gray-900">
                          {Number(product.product_price).toLocaleString(
                            "ko-KR"
                          )}
                        </p>
                        <p className="pl-2 pr-2"> x </p>
                        <p className="text-gray-900">{product.quantity}</p>
                        <p className="pl-2 pr-2"> = </p>
                        <p className="text-gray-900">
                          {Number(product.total).toLocaleString("ko-KR")}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {/* Checkout form */}
          <section
            aria-labelledby="payment-heading"
            className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
          >
            <div className="max-w-lg mx-auto">
              <div className="relative mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                ></div>
                <div className="relative flex justify-center">
                  <h2 className="text-lg font-medium text-gray-900">
                    Checkout
                  </h2>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                <div className="col-span-full">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Receiver Name <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="email-address"
                      name="email-address"
                      value={receiverName}
                      autoComplete="email"
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full h-10 px-2 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-md"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="name-on-card"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Receiver Phone <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="name-on-card"
                      name="name-on-card"
                      value={receiverPhone}
                      autoComplete="cc-name"
                      onChange={(e) => setPhone(e.target.value)}
                      className="block w-full h-10 px-2 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-md"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Address <span className="text-red-600">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      autoComplete="street-address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="block w-full h-10 px-2 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-md"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order Notes
                  </label>
                  <div className="mt-1">
                    <textarea
                      type="text"
                      id="address"
                      name="address"
                      autoComplete="street-address"
                      onChange={(e) => setNote(e.target.value)}
                      className="block w-full h-24 px-2 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-md"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center mb-4 mt-8">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  name="saveInfo"
                  onChange={(e) => setInfo(e.target.checked)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
                />
                <label
                  for="default-checkbox"
                  className="ml-2 text-sm font-medium text-black"
                >
                  Save my personal information
                </label>
              </div>

              <div className="mt-10  border-gray-200 pt-10">
                <h2 className="text-lg font-medium text-gray-900">Payment</h2>

                <fieldset className="mt-4">
                  <legend className="sr-only">Payment type</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {paymentMethods.map((paymentMethod, paymentMethodIdx) => (
                      <div key={paymentMethod.id} className="flex items-center">
                        {paymentMethodIdx === 0 ? (
                          <input
                            id={paymentMethod.id}
                            name="payment-type"
                            type="radio"
                            defaultChecked
                            onChange={() => selectPayment(paymentMethodIdx)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-100 border-2"
                          />
                        ) : (
                          <input
                            id={paymentMethod.id}
                            name="payment-type"
                            type="radio"
                            onChange={() => selectPayment(paymentMethodIdx)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-100 border-2"
                          />
                        )}

                        <label
                          htmlFor={paymentMethod.id}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {paymentMethod.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
                {pmethod === 0 ? (
                  /*<div className="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
        <div className="col-span-4">
          <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
            Card number <span className="text-red-600">*</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="card-number"
              name="card-number"
              autoComplete="cc-number"
              placeholder="**** **** **** ****"
              onChange={(e)=>
                setCard(e.target.value)}
              className="block w-full px-2 h-10 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="col-span-4">
          <label htmlFor="name-on-card" className="block text-sm font-medium text-gray-700">
            Your birthday (6 digits) <span className="text-red-600">*</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="name-on-card"
              name="name-on-card"
              autoComplete="cc-name"
              placeholder="YYMMDD"
              maxLength={6}
              onChange={(e)=>
                setBirthday(e.target.value)}
              className="block w-full px-2 h-10 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="col-span-2">
          <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
            Expiration date (MMYY) <span className="text-red-600">*</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="expiration-date"
              id="expiration-date"
              placeholder="MMYY"
              autoComplete="cc-exp"
              maxLength={4}
              onChange={(e)=>
                setDateExp(e.target.value)}
              className="block w-full px-2 h-10 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="col-span-2">
          <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">
          Card First Two Digit Password <span className="text-red-600">*</span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="cvc"
              id="cvc"
              autoComplete="csc"
              placeholder="__**"
              maxLength={2}
              onChange={(e)=>
                setPass(e.target.value)}
              className="block w-full px-2 h-10 border-gray-100 border-2 rounded-md  focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            />
          </div>
        </div>
      </div>: */
                  <div className="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
                    <div className="col-span-4 bg-gray-50 rounded-md p-4">
                      <label
                        htmlFor="card-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Direct Bank Transfer
                      </label>
                      <div className="mt-1">
                        <div>
                          <p className="text-gray-700">
                            Please make your payment directly into our bank
                            account. Please use your Order Number as the payment
                            reference. Your order will not be shipped until the
                            funds have been cleared in our account. Thank you!
                          </p>
                          <p className="text-gray-700 mt-8">
                            Please input your address in Korean (Hangul) so we
                            can locate you easily. And do not forget to include
                            your room number too. Thank you! :)
                          </p>
                          <p className="text-gray-700 mt-4">
                            ⚡NOTE: Orders paid by 2pm will be shipped out the
                            same day.
                          </p>
                          <h4 className="text-bold text-xl mt-2">
                            {option["bank"]}
                          </h4>
                          <h4 className="text-bold text-xl">
                            {option["bank_no"]}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
                    <div className="col-span-4 bg-gray-50 rounded-md p-4">
                      <label
                        htmlFor="card-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Direct Bank Transfer
                      </label>
                      <div className="mt-1">
                        <div>
                          <p className="text-gray-700">
                            Please make your payment directly into our bank
                            account. Please use your Order Number as the payment
                            reference. Your order will not be shipped until the
                            funds have been cleared in our account. Thank you!
                          </p>
                          <p className="text-gray-700 mt-8">
                            Please input your address in Korean (Hangul) so we
                            can locate you easily. And do not forget to include
                            your room number too. Thank you! :)
                          </p>
                          <p className="text-gray-700 mt-4">
                            ⚡NOTE: Orders paid by 2pm will be shipped out the
                            same day.
                          </p>
                          <h4 className="text-bold text-xl mt-2">
                            {option["bank"]}
                          </h4>
                          <h4 className="text-bold text-xl">
                            {option["bank_no"]}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <button
                onClick={() => {
                  if (
                    receiverName !== "" &&
                    receiverPhone !== "" &&
                    address !== ""
                  ) {
                    checkOut();
                  } else {
                    console.log("Please provide user information");
                    setUserErrorMessage(true);
                  }
                }}
                className="w-full mt-6 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Pay {total + option["shipping"]}
                <span className="text-[10px]">₩</span>
              </button>
            </div>
          </section>
        </main>
      )}

      {openCart && (
        <Cart
          handleCart={handleCart}
          cart={cart}
          user={user}
          getCartData={getCartData}
        />
      )}
      {success && <Success text="You've successfully placed order!" />}
      {openNav && (
        <Navigator
          handleNavigator={handleNavigator}
          handleSignout={handleSignout}
        />
      )}
      {userinfoMessage && (
        <div
          className="w-full h-screen fixed bottom-0 flex duration-300 cursor-pointer"
          onClick={() => setUserErrorMessage(false)}
        >
          <div className="absolute flex justify-center inset-x-0 bg-red-500 bottom-0 h-24">
            <button>
              <p className="text-xl font-semibold text-white">
                Name, Phone, Address fields must be completed!
              </p>
            </button>
            <button>
              <p className="text-lg ml-4 bg-gray-700 shadow-lg p-2 rounded-lg hover:bg-black font-semibold text-white">
                Close
              </p>
            </button>
          </div>
        </div>
      )}
      {errorMessage && (
        <div
          className="w-full h-screen fixed bottom-0 flex duration-300 cursor-pointer"
          onClick={() => setErrorMessage(false)}
        >
          <div className="absolute flex justify-center inset-x-0 bg-red-500 bottom-0 h-24">
            <button>
              <p className="text-lg font-semibold text-white">
                Please check your card information or balance!
              </p>
            </button>
            <button>
              <p className="text-lg ml-4 bg-gray-700 shadow-lg p-2 rounded-lg hover:bg-black font-semibold text-white">
                Close
              </p>
            </button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Checkout;
