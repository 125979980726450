import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

const Navigator = (props) => {
    return (
        <div className="w-full h-screen  fixed top-0 left-0 flex justify-end">
            <div className="mt-[100px] px-2 relative bg-slate-50  2xl:w-[25%] lg:w-[35%] md:w-[50%] w-full h-full overflow-y-auto shadow-sm">
            <div className="w-full h-16 mt-0 shadow-md flex px-4 justify-between rounded-sm bg-white">
                            <button><p className="text-2xl font-bold ">Menu</p></button>
                            <button className="text-zinc-800 hover:text-red-600" onClick={()=> props.handleNavigator()}><XIcon className="stroke-1 w-9 h-9"/></button> 
                        </div>
                <div class="w-full mt-3 text-gray-900 bg-white rounded-lg border border-gray-200 ">
                    <Link to="/profile"> 
                    <button type="button" class="inline-flex relative items-center py-2 px-4  w-full text-md font-medium rounded-t-lg border-b border-gray-200 hover:bg-green-500 hover:text-white">
                        <svg aria-hidden="true" class="mr-2 w-6 h-6 fill-current" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                        My Profile
                    </button>
                    </Link>
                    <Link to="/about">
                    <button type="button" class="inline-flex relative items-center py-2 px-4  w-full text-md font-medium rounded-t-lg border-b border-gray-200 hover:bg-green-500 hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                        About
                    </button></Link> 
                    <button type="button" class="inline-flex relative items-center py-2 px-4  w-full text-md font-medium rounded-t-lg border-b border-gray-200 hover:bg-green-500 hover:text-white" onClick={()=> props.handleSignout()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
</svg>
                        Sign Out
                    </button> 
                </div>
            </div>
            <div className="bg-[#00000050] 2xl:w-full xl:w-full lg-w-full md:w-full w-0" onClick={()=> props.handleNavigator()}>
            </div>
        </div>
    );
}
 
export default Navigator;