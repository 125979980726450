import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { fadeIn } from "react-animations";
import { motion, AnimatePresence } from "framer-motion";

const SignIn = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [otpnumber, setotpnumber] = useState();

  const navigate = useNavigate();

  const handleChangeOtp = (e) => {
    console.log(e.target.value);
    setOtp(e.target.value);
  };

  const verify = () => {
    axios
      .post("https://albazaarkorea.com/api/createUser", {
        phone: phone,
      })
      .then(function (response) {
        setotpnumber(response.data.activation_code);
        console.log(response.data.activation_code);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const clickOtp = () => {
    setShowOtp(true);
    verify();
  };

  const checkOtp = () => {
    if (otpnumber == otp) {
      setWrong(false);
      localStorage.setItem("user", phone);
      navigate("/");
    } else {
      console.log("wrong");
      setWrong(true);
    }
  };

  const goBack = () => {
    setShowOtp(false);
    setWrong(false);
  };

  const [user, setUser] = useState("");

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log(loggedInUser);
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  useEffect(() => {
    if (user !== "") {
      getCartData();
    }
  }, [user]);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCart(data["cart"]);
      });
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  return (
    <>
      <Navbar
        homepage={false}
        user={user}
        handleSignout={handleSignout}
        handleCart={handleCart}
        cart={cart}
        cartlength={0}
      />
      {!showOtp && (
        <div className="w-full h-screen  fixed top-0 left-0 flex justify-center  bg-slate-50">
          <div className="mt-[200px] bg-white text-center flex justify-center pt-10 relative lg:flex lg:w-[40%] lg:h-[50%] md:w-[70%] md:h-[45%] w-[90%] h-[55%] rounded-lg shadow-lg">
            <div className="c-1 lg:w-[60%] md:w-[60%] w-[90%]">
              <p className="text-2xl font-bold">Sign In</p>
              <div className="w-full mt-12">
                <p className="text-xl font-bold"> Welcome </p>
                <p> Enter your korean mobile phone number</p>
                <form className="px-2 pt-6 pb-8 mb-4">
                  <div className="mb-4 pt-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Ex: 01012345678
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="phone"
                      type="text"
                      maxLength="11"
                      placeholder="Phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <button
                    className="bg-zinc-200 w-[60%] hover:bg-green-500 hover:text-white text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() => clickOtp()}
                  >
                    Sign in
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {showOtp && (
        <div className="w-full h-screen  fixed top-0 left-0 flex justify-center  bg-slate-50">
          <div className="mt-[200px] bg-white text-center flex justify-center pt-10 relative lg:flex lg:w-[40%] lg:h-[50%] md:w-[70%] md:h-[50%] w-[90%] h-[50%] rounded-lg shadow-lg">
            <div className="c-1 lg:w-[60%] md:w-[60%] w-[90%]">
              <p className="text-2xl font-bold">Verification</p>
              <div className="w-full mt-12">
                <p className="text-xl font-bold"> Verification Code</p>
                <p> Sms was sent to {phone}</p>
                <form className="px-2 pt-6 pb-8 mb-4">
                  <div className="mb-4 pt-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Enter OTP number
                    </label>
                    <input
                      value={null}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="otp"
                      type="number"
                      maxLength="6"
                      placeholder="OTP Code"
                      onChange={handleChangeOtp}
                    />
                  </div>
                  <button
                    className="bg-zinc-200 w-[60%] hover:bg-green-500 hover:text-white text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() => checkOtp()}
                  >
                    Verify
                  </button>
                  {wrong && (
                    <div className="pt-2">
                      <p className=" text-red-400 ">Wrong Otp number </p>
                      <button
                        className="p-2 bg-blue-800 text-white rounded-lg pt-1 mt-1"
                        onClick={() => goBack()}
                      >
                        Go back
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
