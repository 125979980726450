import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Cart from "./Cart";
import Navbar from "./Navbar";
import Navigator from "./Navigator";

const About = () => {
  const [user, setUser] = useState("");

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log(loggedInUser);
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  useEffect(() => {
    if (user !== "") {
      getCartData();
    }
  }, [user]);

  const [cartlength, setCartLength] = useState(0);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCart(data["cart"]);
        setCartLength(data["cart"].length);
      });
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeSuccuess = () => {
    setSuccess(false);
    console.log(success);
  };

  const successMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      closeSuccuess();
    }, 2000);
  };

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  const addtocart = (user, cart) => {
    axios
      .post("https://albazaarkorea.com/api/addCart", {
        phone: user,
        cart: cart,
      })
      .then(function (response) {
        getCartData();
        successMessage();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [openNav, setOpenNav] = useState(false);
  const handleNavigator = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      <Navbar
        homepage={false}
        user={user}
        handleSignout={handleSignout}
        handleCart={handleCart}
        cart={cart}
        cartlength={cartlength}
        handleNavigator={handleNavigator}
      />
      <div className="w-full fixed h-full mt-[80px] bg-white overflow-y-auto">
        <div className="w-[80%] px-32 bg-white">
          <div className="mt-9 ">
            <h5 className="font-bold text-xl">History</h5>
            <p className="font-medium text-lg">
              In South Korea, Islam (이슬람교) is a minority religion. The
              Muslim community is mostly centered in Seoul and Mosques were
              built around the country. According to the Korea Muslim
              Federation, about 100,000 Muslims are living in the beautiful land
              of South Korea, both Koreans and foreigners. With the undeniable
              growth of Muslim people in the country, comes the increasing
              demand for halal products. And it’s a great challenge to find
              these because of the diversity of the communities around Seoul. We
              know it because we ourselves had problems finding halal food
              inside the country. Majority of the Muslim community in South
              Korea are foreigners – working, studying or on a vacation. Most of
              them are very busy to scrutinize the cities finding halal
              groceries. With these in mind, we brought up AL BAZAAR KOREA. AL
              BAZAAR KOREA is an e-commerce company aiming to help Muslim people
              in South Korea have a better life in a foreign country. Since the
              cost of living here is not cheap, it’s always advisable to prepare
              your own meal at home. You are sure it’s halal, you are sure it’s
              healthy. Thus, we promote halal meat and groceries and soon to
              expand to more varieties of products. The company’s main goal is
              establishing a friendly community and encourage people to eat
              halal food no matter how busy they are. Al Bazaar will bring you
              the halal market right to your doorsteps. So, if you are looking
              for high-quality halal meat and groceries for very affordable
              prices, Al Bazaar is the best shop to get these. Not only that we
              deliver fast, but we also deliver anywhere in South Korea.
              Experience the highest convenience of shopping halal products. AL
              BAZAAR KOREA
            </p>
          </div>
          <div className="mt-8">
            <p className="font-bold text-xl">Business Information</p>
            <p className="font-medium text-lg">
              서울시 영산구 우사단로10길23 지하층
            </p>
            <p className="font-medium text-lg">
              사업자등록증번호: 253-59-00387
            </p>
            <p className="font-medium text-lg">
              통신판매신고번호: 2018-서울용산-0694
            </p>
            <p className="font-medium text-lg">대표: 트레이드웨이(Trade way)</p>
            <p className="font-medium text-lg">전화: 010-8779-0672</p>
            <p className="font-medium text-lg">팩스: 02)6404-7775</p>
            <p className="font-medium text-lg">
              이메일: albazaarkorea@gmail.com
            </p>
          </div>
        </div>
        <div className="h-[300px]"></div>
      </div>
      {openCart && (
        <Cart
          handleCart={handleCart}
          cart={cart}
          user={user}
          getCartData={getCartData}
        />
      )}
      {openNav && (
        <Navigator
          handleNavigator={handleNavigator}
          handleSignout={handleSignout}
        />
      )}
    </>
  );
};

export default About;
