import React from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Products from "../Product";
import { useLayoutEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import axios from "axios";
import Cart from "../Cart";
import Success from "../Success";
import Navigator from "../Navigator";
import { motion } from "framer-motion";

const Category = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth <= 800;
    setIsMobile(isMobile);
    // Add the following code to disable horizontal scroll on mount
    document.body.style.overflowX = "hidden";

    // Clean up the code to re-enable horizontal scroll on unmount
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  const [category, setCategory] = useState([]);
  useEffect(() => {
    fetch("https://albazaarkorea.com/api/subcategory")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategory(data);
      });
  }, []);
  const { id } = useParams();
  const [catProducts, setCatProduct] = useState([]);

  useEffect(() => {
    fetch(`https://albazaarkorea.com/api/categories/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let d = data.filter((item) => item.avail !== 0);
        setCatProduct(d.reverse());
      });
  }, [id]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [user, setUser] = useState("");

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      console.log(loggedInUser);
      setUser(loggedInUser);
    }
  }, []);

  const handleSignout = () => {
    localStorage.clear();
    setUser("");
    window.location.reload(false);
  };

  useEffect(() => {
    if (user !== "") {
      getCartData();
    }
  }, [user]);

  const [cartlength, setCartLength] = useState(0);

  const getCartData = () => {
    fetch(`https://albazaarkorea.com/api/getCart/${user}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setCart(data["cart"]);
        setCartLength(data["cart"].length);
      });
  };

  const [cart, setCart] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [success, setSuccess] = useState(false);

  const closeSuccuess = () => {
    setSuccess(false);
    console.log(success);
  };

  const successMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      closeSuccuess();
    }, 2000);
  };

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  const addtocart = (user, cart) => {
    axios
      .post("https://albazaarkorea.com/api/addCart", {
        phone: user,
        cart: cart,
      })
      .then(function (response) {
        getCartData();
        successMessage();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [openNav, setOpenNav] = useState(false);
  const handleNavigator = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      {isMobile ? (
        <div className="flex h-full flex-col justify-center items-center  bg-white">
          <div className="flex items-center justify-center mt-2  shadow-lg w-full">
            <h1 className="text-[50px] text-green-500 font-semibold font-bebas mr-4 ml-4 drop-shadow-sm">
              Albazaar
            </h1>
          </div>
          <img
            src="app.png"
            className="w-[90%] max-h-[100vh] mt-8 object-contain mb-8"
            alt=""
          />
          <div className="text-center mt-10">
            <h1 className="font-bold text-xl">
              Get things done with Albazaar App
            </h1>
            <p className="text-gray-700 text-md font-light mt-2">
              Easy to get started!
            </p>
            <div className="flex items-center justify-center mt-8">
              <a
                href="https://play.google.com/store/apps/details?id=com.albazaar.albazaar_app"
                target="_blank"
              >
                <button className="bg-black text-white px-4 py-2 rounded-xl hover:bg-green-500">
                  Download Android
                </button>
              </a>
              <a
                href="https://apps.apple.com/kr/app/albazaar-korea/id1613283664?l=en"
                target="_blank"
              >
                <button className="bg-black text-white ml-4 px-4 py-2 rounded-xl hover:bg-green-500">
                  Download iOS
                </button>
              </a>
            </div>
            <div className="pt-20"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-slate-100">
            <Navbar
              homepage={false}
              user={user}
              handleSignout={handleSignout}
              handleCart={handleCart}
              cart={cart}
              cartlength={cartlength}
              handleNavigator={handleNavigator}
            />
            <div className="px-0 4xl:px-[600px] xxl:px-[400px] 2xl:px-[300px] 3xl:px-[100px] xl:px-[200px]">
              <Sidebar category={category} />

              <div className="md:ml-80 md:pt-[120px] pt-10 px-8 h-full bg-slate-50">
                <div className="h-10 text-center rounded-lg shadow-sm bg-green-200">
                  <h2 className="font-bold text-black pt-2">{id}</h2>
                </div>

                {cart && catProducts && (
                  <Products
                    products={catProducts}
                    addtocart={addtocart}
                    user={user}
                  />
                )}
              </div>

              {openCart && (
                <Cart
                  handleCart={handleCart}
                  cart={cart}
                  user={user}
                  getCartData={getCartData}
                />
              )}
              {success && <Success text="Product Added Successfully" />}
              {openNav && (
                <Navigator
                  handleNavigator={handleNavigator}
                  handleSignout={handleSignout}
                />
              )}
              <div className="h-screen bg-slate-50"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Category;
